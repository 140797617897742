import createTsdApi from "../../createTsdApi";
import {
  ProductSupplierAccount,
  ProductSupplierAccountCreateParams,
  ProductSupplierAccountQueryParams,
  ProductSupplierAccountUpdateParams,
} from "../../../entities/productSupplierAccount";
import { TsdSearchParam } from "../fetch";

const productSupplierAccountApi = createTsdApi<
  ProductSupplierAccount,
  ProductSupplierAccountQueryParams,
  ProductSupplierAccountCreateParams,
  ProductSupplierAccountUpdateParams
>("product-supplier-accounts", (searchParamsObject) => {
  let searchParams: TsdSearchParam<ProductSupplierAccountQueryParams>[] = [];

  if (typeof searchParamsObject.supplierAccountId !== "undefined") {
    searchParams.push({
      key: "supplierAccountId",
      value: String(searchParamsObject.supplierAccountId),
    });
  }

  if (typeof searchParamsObject.productId !== "undefined") {
    searchParams.push({
      key: "productId",
      value: String(searchParamsObject.productId),
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default productSupplierAccountApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  Supplier,
  SupplierQueryParams,
  SupplierCreateParams,
  SupplierUpdateParams,
} from "../../../entities/supplier";
import { TsdSearchParam } from "../fetch";

const supplierApi = createTsdApi<
  Supplier,
  SupplierQueryParams,
  SupplierCreateParams,
  SupplierUpdateParams
>("suppliers", (searchParamsObject) => {
  let searchParams: TsdSearchParam<SupplierQueryParams>[] = [];

  if (typeof searchParamsObject.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: searchParamsObject.isActive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: searchParamsObject.isDeleted ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.searchTerm !== "undefined") {
    searchParams.push({
      key: "searchTerm",
      value: searchParamsObject.searchTerm,
    });
  }

  if (typeof searchParamsObject.addressSearchTerm !== "undefined") {
    searchParams.push({
      key: "addressSearchTerm",
      value: searchParamsObject.addressSearchTerm,
    });
  }

  if (typeof searchParamsObject.contactSearchTerm !== "undefined") {
    searchParams.push({
      key: "contactSearchTerm",
      value: searchParamsObject.contactSearchTerm,
    });
  }

  if (typeof searchParamsObject.isArchived !== "undefined") {
    searchParams.push({
      key: "isArchived",
      value: searchParamsObject.isArchived ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isPrivateSupplier !== "undefined") {
    searchParams.push({
      key: "isPrivateSupplier",
      value: searchParamsObject.isPrivateSupplier ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.customerIdsWithAccess !== "undefined") {
    searchParamsObject.customerIdsWithAccess.forEach((id) => {
      searchParams.push({
        key: "customerIdsWithAccess",
        value: String(id),
      });
    });
  }

  return searchParams;
});

export default supplierApi;

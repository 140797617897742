import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNoteTag,
  CustomerNoteTagQueryParams,
  CustomerNoteTagCreateParams,
  CustomerNoteTagUpdateParams,
} from "../../../entities/CustomerNoteTag/customerNoteTag";
import { TsdSearchParam } from "../fetch";

const customerNoteTagApi = createTsdApi<
  CustomerNoteTag,
  CustomerNoteTagQueryParams,
  CustomerNoteTagCreateParams,
  CustomerNoteTagUpdateParams
>("customer-note-tags", (params) => {
  const searchParams: TsdSearchParam<CustomerNoteTagQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.tags !== "undefined") {
    params.tags.forEach((tag) => {
      searchParams.push({ key: "tags", value: tag });
    });
  }

  if (typeof params.customerNoteIds !== "undefined") {
    params.customerNoteIds.forEach((id) => {
      searchParams.push({ key: "customerNoteIds", value: String(id) });
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  return searchParams;
});

export default customerNoteTagApi;

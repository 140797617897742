import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierOrgContact,
  SupplierOrgContactQueryParams,
  SupplierOrgContactCreateParams,
  SupplierOrgContactUpdateParams,
} from "../../../entities/SupplierOrgContact/supplierOrgContact";
import { TsdSearchParam } from "../fetch";

const supplierOrgContactApi = createTsdApi<
  SupplierOrgContact,
  SupplierOrgContactQueryParams,
  SupplierOrgContactCreateParams,
  SupplierOrgContactUpdateParams
>("supplier-org-contacts", (params) => {
  const searchParams: TsdSearchParam<SupplierOrgContactQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      searchParams.push({
        key: "orgIds",
        value: String(id),
      });
    });
  }

  if (typeof params.contactIds !== "undefined") {
    params.contactIds.forEach((id) => {
      searchParams.push({
        key: "contactIds",
        value: String(id),
      });
    });
  }

  if (typeof params.isReceivesDailySummary !== "undefined") {
    searchParams.push({
      key: "isReceivesDailySummary",
      value: params.isReceivesDailySummary ? "1" : "0",
    });
  }

  if (typeof params.isReceivesNotifications !== "undefined") {
    searchParams.push({
      key: "isReceivesNotifications",
      value: params.isReceivesNotifications ? "1" : "0",
    });
  }

  return searchParams;
});

export default supplierOrgContactApi;

import {
  BudgetProduct,
  BudgetProductCreateParams,
  BudgetProductQueryParams,
  BudgetProductUpdateParams,
} from "../../../entities/BudgetProduct/budgetProduct";
import createTsdApi from "../../../client-api/createTsdApi";
import { TsdSearchParam } from "../fetch";
import dayjs from "dayjs";

const budgetProductApi = createTsdApi<
  BudgetProduct,
  BudgetProductQueryParams,
  BudgetProductCreateParams,
  BudgetProductUpdateParams
>("budget-products", (params) => {
  const searchParams: TsdSearchParam<BudgetProductQueryParams>[] = [];

  if (typeof params.budgetIds !== "undefined") {
    params.budgetIds.forEach((id) => {
      searchParams.push({
        key: "budgetIds",
        value: String(id),
      });
    });
  }

  if (typeof params.productOriginalIds !== "undefined") {
    params.productOriginalIds.forEach((id) => {
      searchParams.push({
        key: "productOriginalIds",
        value: String(id),
      });
    });
  }

  if (typeof params.statuses !== "undefined") {
    params.statuses.forEach((status) => {
      searchParams.push({
        key: "statuses",
        value: status,
      });
    });
  }

  if (typeof params.finalDateGteOrNull !== "undefined") {
    searchParams.push({
      key: "finalDateGteOrNull",
      value: dayjs()
        .fromTsdDateFormat(params.finalDateGteOrNull)
        .format("YYYY-MM-DD"),
    });
  }

  if (typeof params.startDateLte !== "undefined") {
    searchParams.push({
      key: "startDateLte",
      value: dayjs()
        .fromTsdDateFormat(params.startDateLte)
        .format("YYYY-MM-DD"),
    });
  }

  if (typeof params.isCurrent !== "undefined") {
    searchParams.push({
      key: "isCurrent",
      value: params.isCurrent ? "1" : "0",
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  return searchParams;
});

export default budgetProductApi;

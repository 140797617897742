import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierAccount,
  SupplierAccountCreateParams,
  SupplierAccountQueryParams,
  SupplierAccountUpdateParams,
} from "../../../entities/supplierAccount";
import { TsdSearchParam } from "../fetch";

const supplierAccountApi = createTsdApi<
  SupplierAccount,
  SupplierAccountQueryParams,
  SupplierAccountCreateParams,
  SupplierAccountUpdateParams
>("supplier-accounts", (params) => {
  const searchParams: TsdSearchParam<SupplierAccountQueryParams>[] = [];

  if (typeof params.supplierId !== "undefined") {
    searchParams.push({ key: "supplierId", value: String(params.supplierId) });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({ key: "offset", value: String(params.offset) });
  }

  return searchParams;
});

export default supplierAccountApi;

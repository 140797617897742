import createTsdApi from "../../../client-api/createTsdApi";
import {
  StaffOvertime,
  StaffOvertimeCreateParams,
  StaffOvertimeQueryParams,
  StaffOvertimeUpdateParams,
} from "../../../entities/StaffOvertime/staffOvertime";
import { TsdSearchParam } from "../fetch";

const staffOvertimeApi = createTsdApi<
  StaffOvertime,
  StaffOvertimeQueryParams,
  StaffOvertimeCreateParams,
  StaffOvertimeUpdateParams
>("staff-overtimes", (params) => {
  const search: TsdSearchParam<StaffOvertimeQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.staffIds !== "undefined") {
    params.staffIds.forEach((id) =>
      search.push({ key: "staffIds", value: String(id) })
    );
  }

  if (typeof params.isDeleted !== "undefined") {
    search.push({ key: "isDeleted", value: params.isDeleted ? "1" : "0" });
  }

  if (typeof params.startDatetimeGte !== "undefined") {
    search.push({
      key: "startDatetimeGte",
      value: params.startDatetimeGte,
    });
  }

  if (typeof params.endDatetimeLte !== "undefined") {
    search.push({
      key: "endDatetimeLte",
      value: params.endDatetimeLte,
    });
  }

  if (typeof params.orderByCreated !== "undefined") {
    search.push({ key: "orderByCreated", value: params.orderByCreated });
  }

  return search;
});

export default staffOvertimeApi;

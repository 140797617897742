import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNoteAttributeValue,
  CustomerNoteAttributeValueCreateParams,
  CustomerNoteAttributeValueQueryParams,
  CustomerNoteAttributeValueUpdateParams,
} from "../../../entities/CustomerNoteAttributeValue/customerNoteAttributeValue";
import { TsdSearchParam } from "../fetch";

const customerNoteAttributeValueApi = createTsdApi<
  CustomerNoteAttributeValue,
  CustomerNoteAttributeValueQueryParams,
  CustomerNoteAttributeValueCreateParams,
  CustomerNoteAttributeValueUpdateParams
>("customer-note-attribute-values", (params) => {
  const search: TsdSearchParam<CustomerNoteAttributeValueQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.customerNoteIds !== "undefined") {
    params.customerNoteIds.forEach((id) => {
      search.push({ key: "customerNoteIds", value: String(id) });
    });
  }

  if (typeof params.customerNoteTagAttributeIds !== "undefined") {
    params.customerNoteTagAttributeIds.forEach((id) => {
      search.push({ key: "customerNoteTagAttributeIds", value: String(id) });
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    search.push({ key: "isDeleted", value: params.isDeleted ? "1" : "0" });
  }

  return search;
});

export default customerNoteAttributeValueApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  User,
  UserQueryParams,
  UserCreateParams,
  UserUpdateParams,
} from "../../../entities/user";
import { TsdSearchParam } from "../fetch";

const userApi = createTsdApi<
  User,
  UserQueryParams,
  UserCreateParams,
  UserUpdateParams
>("users", (searchParamsObject) => {
  let searchParams: TsdSearchParam<UserQueryParams>[] = [];

  if (typeof searchParamsObject.isDeveloper !== "undefined") {
    searchParams.push({
      key: "isDeveloper",
      value: searchParamsObject.isDeveloper ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: searchParamsObject.isActive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isInactive !== "undefined") {
    searchParams.push({
      key: "isInactive",
      value: searchParamsObject.isInactive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: searchParamsObject.isDeleted ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.staffId !== "undefined") {
    searchParams.push({
      key: "staffId",
      value: String(searchParamsObject.staffId),
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default userApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductSizeOption,
  ProductSizeOptionCreateParams,
  ProductSizeOptionQueryParams,
  ProductSizeOptionUpdateParams,
} from "../../../entities/ProductSizeOption/productSizeOption";
import { TsdSearchParam } from "../fetch";

const productSizeOptionApi = createTsdApi<
  ProductSizeOption,
  ProductSizeOptionQueryParams,
  ProductSizeOptionCreateParams,
  ProductSizeOptionUpdateParams
>("product-size-options", (params) => {
  const searchParams: TsdSearchParam<ProductSizeOptionQueryParams>[] = [];

  if (typeof params.productId !== "undefined") {
    searchParams.push({ key: "productId", value: String(params.productId) });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  return searchParams;
});

export default productSizeOptionApi;

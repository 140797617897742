import createTsdApi from "../../../client-api/createTsdApi";
import {
  ICustomer,
  ICustomerCreateHttpBody,
  ICustomerQueryParams,
  ICustomerUpdateHttpBody,
} from "../../../entities/Customer/customer";
import tsdFetch, { ITsdFetch, TsdSearchParam } from "../fetch";
import { CustomerContactCreateParams } from "../../../entities/CustomerContact/customerContact";

const customerApi = createTsdApi<
  ICustomer,
  ICustomerQueryParams,
  ICustomerCreateHttpBody,
  ICustomerUpdateHttpBody
>("customers", (params) => {
  const search: TsdSearchParam<ICustomerQueryParams>[] = [];

  if (typeof params.status !== "undefined") {
    search.push({
      key: "status",
      value: params.status,
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    search.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.searchTerm !== "undefined") {
    search.push({
      key: "searchTerm",
      value: params.searchTerm,
    });
  }

  if (typeof params.addressSearchTerm !== "undefined") {
    search.push({
      key: "addressSearchTerm",
      value: params.addressSearchTerm,
    });
  }

  if (typeof params.contactSearchTerm !== "undefined") {
    search.push({
      key: "contactSearchTerm",
      value: params.contactSearchTerm,
    });
  }

  if (typeof params.isArchived !== "undefined") {
    search.push({
      key: "isArchived",
      value: params.isArchived ? "1" : "0",
    });
  }

  if (typeof params.sectorId !== "undefined") {
    search.push({
      key: "sectorId",
      value: String(params.sectorId),
    });
  }

  if (typeof params.sortCustomerCode !== "undefined") {
    search.push({
      key: "sortCustomerCode",
      value: params.sortCustomerCode,
    });
  }

  if (typeof params.sortName !== "undefined") {
    search.push({
      key: "sortName",
      value: params.sortName,
    });
  }

  if (typeof params.recordType !== "undefined") {
    search.push({
      key: "recordType",
      value: params.recordType,
    });
  }

  if (typeof params.ids !== "undefined") {
    params.ids.forEach((id) => {
      search.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof params.groupIds !== "undefined") {
    params.groupIds.forEach((id) => {
      search.push({
        key: "groupIds",
        value: String(id),
      });
    });
  }

  if (typeof params.isRandomCustomer !== "undefined") {
    search.push({
      key: "isRandomCustomer",
      value: params.isRandomCustomer ? "1" : "0",
    });
  }

  if (typeof params.randomCustomersLimit !== "undefined") {
    search.push({
      key: "randomCustomersLimit",
      value: String(params.randomCustomersLimit),
    });
  }

  if (typeof params.primaryContactIds !== "undefined") {
    params.primaryContactIds.forEach((id) => {
      search.push({
        key: "primaryContactIds",
        value: String(id),
      });
    });
  }

  return search;
});

type CreateFunc = (
  customerId: number,
  contactId: number,
  siteContact: CustomerContactCreateParams
) => ITsdFetch<ICustomer>;

const createContact: CreateFunc = async (
  customerId,
  contactId,
  siteContact
) => {
  const url = `/api/v1/customers/${customerId}/contacts/${contactId}`;
  return tsdFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(siteContact),
  });
};

export default { ...customerApi, createContact };

import createTsdApi from "../../createTsdApi";
import {
  StaffSickDay,
  StaffSickDayCreateParams,
  StaffSickDayQueryParams,
  StaffSickDayUpdateParams,
} from "../../../entities/StaffSickDay/staffSickDay";
import { TsdSearchParam } from "../fetch";

const staffSickDayApi = createTsdApi<
  StaffSickDay,
  StaffSickDayQueryParams,
  StaffSickDayCreateParams,
  StaffSickDayUpdateParams
>("staff-sick-days", (params) => {
  const search: TsdSearchParam<StaffSickDayQueryParams>[] = [];

  if (typeof params.isDeleted !== "undefined") {
    search.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  if (typeof params.staffIds !== "undefined") {
    params.staffIds.forEach((id) => {
      search.push({
        key: "staffIds",
        value: String(id),
      });
    });
  }

  if (typeof params.sortByStartDate !== "undefined") {
    search.push({
      key: "sortByStartDate",
      value: params.sortByStartDate,
    });
  }

  return search;
});

export default staffSickDayApi;

import {
  OrderItem,
  OrderItemCreateParams,
  OrderItemQueryParams,
  OrderItemUpdateParams,
} from "../../../entities/OrderItem/orderItem";
import createTsdApi from "../../../client-api/createTsdApi";
import { TsdSearchParam } from "../fetch";

const orderItemApi = createTsdApi<
  OrderItem,
  OrderItemQueryParams,
  OrderItemCreateParams,
  OrderItemUpdateParams
>("order-items", (searchParamsObject) => {
  let searchParams: TsdSearchParam<OrderItemQueryParams>[] = [];

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.supplierIds !== "undefined") {
    searchParamsObject.supplierIds.forEach((id) => {
      searchParams.push({
        key: "supplierIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.orderIds !== "undefined") {
    searchParamsObject.orderIds.forEach((id) => {
      searchParams.push({
        key: "orderIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.customerIds !== "undefined") {
    searchParamsObject.customerIds.forEach((id) => {
      searchParams.push({
        key: "customerIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.jobDeliveryDateGte !== "undefined") {
    searchParams.push({
      key: "jobDeliveryDateGte",
      value: searchParamsObject.jobDeliveryDateGte,
    });
  }

  if (typeof searchParamsObject.jobDeliveryDateLte !== "undefined") {
    searchParams.push({
      key: "jobDeliveryDateLte",
      value: searchParamsObject.jobDeliveryDateLte,
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.statuses !== "undefined") {
    searchParamsObject.statuses.forEach((status) => {
      searchParams.push({
        key: "statuses",
        value: status,
      });
    });
  }

  return searchParams;
});

export default orderItemApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  RelatedProduct,
  RelatedProductCreateParams,
  RelatedProductQueryParams,
  RelatedProductUpdateParams,
} from "../../../entities/RelatedProduct/relatedProduct";
import { TsdSearchParam } from "../fetch";

const relatedProductApi = createTsdApi<
  RelatedProduct,
  RelatedProductQueryParams,
  RelatedProductCreateParams,
  RelatedProductUpdateParams
>("related-products", (searchParamsObject) => {
  let searchParams: TsdSearchParam<RelatedProductQueryParams>[] = [];

  if (typeof searchParamsObject.createdGte !== "undefined") {
    searchParams.push({
      key: "createdGte",
      value: searchParamsObject.createdGte.toISOString(),
    });
  }

  if (typeof searchParamsObject.createdLte !== "undefined") {
    searchParams.push({
      key: "createdLte",
      value: searchParamsObject.createdLte.toISOString(),
    });
  }

  if (typeof searchParamsObject.targetProductOriginalIds !== "undefined") {
    searchParams.push({
      key: "targetProductOriginalIds",
      value: String(searchParamsObject.targetProductOriginalIds),
    });
  }

  if (typeof searchParamsObject.relatedProductOriginalIds !== "undefined") {
    searchParams.push({
      key: "relatedProductOriginalIds",
      value: String(searchParamsObject.relatedProductOriginalIds),
    });
  }

  if (typeof searchParamsObject.isSimilar !== "undefined") {
    searchParams.push({
      key: "isSimilar",
      value: searchParamsObject.isSimilar ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isAlternative !== "undefined") {
    searchParams.push({
      key: "isAlternative",
      value: searchParamsObject.isAlternative ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default relatedProductApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierContact,
  SupplierContactCreateParams,
  SupplierContactQueryParams,
  SupplierContactUpdateParams,
} from "../../../entities/SupplierContact/supplierContact";
import { TsdSearchParam } from "../fetch";

const supplierContactApi = createTsdApi<
  SupplierContact,
  SupplierContactQueryParams,
  SupplierContactCreateParams,
  SupplierContactUpdateParams
>("supplier-contacts", (params) => {
  const searchParams: TsdSearchParam<SupplierContactQueryParams>[] = [];

  if (typeof params.ids !== "undefined") {
    params.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof params.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: params.isActive ? "1" : "0",
    });
  }

  if (typeof params.isInactive !== "undefined") {
    searchParams.push({
      key: "isInactive",
      value: params.isInactive ? "1" : "0",
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.searchTerm !== "undefined") {
    searchParams.push({
      key: "searchTerm",
      value: params.searchTerm,
    });
  }

  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      searchParams.push({
        key: "orgIds",
        value: String(id),
      });
    });
  }

  if (typeof params.telephoneSearchTerm !== "undefined") {
    searchParams.push({
      key: "telephoneSearchTerm",
      value: params.telephoneSearchTerm,
    });
  }

  return searchParams;
});

export default supplierContactApi;

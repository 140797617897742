import createTsdApi from "../../../client-api/createTsdApi";
import {
  BudgetAmountLog,
  BudgetAmountLogCreateParams,
  BudgetAmountLogQueryParams,
  BudgetAmountLogUpdateParams,
} from "../../../entities/BudgetAmountLog/budgetAmountLog";
import { TsdSearchParam } from "../fetch";

const budgetAmountLogApi = createTsdApi<
  BudgetAmountLog,
  BudgetAmountLogQueryParams,
  BudgetAmountLogCreateParams,
  BudgetAmountLogUpdateParams
>("budget-amount-logs", (params) => {
  const search: TsdSearchParam<BudgetAmountLogQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  return search;
});

export default budgetAmountLogApi;

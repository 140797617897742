import createTsdApi from "../../../client-api/createTsdApi";
import {
  OrderItemCredit,
  OrderItemCreditCreateParams,
  OrderItemCreditQueryParams,
  OrderItemCreditUpdateParams,
} from "../../../entities/OrderItemCredit/orderItemCredit";
import { TsdSearchParam } from "../fetch";

const orderItemCreditApi = createTsdApi<
  OrderItemCredit,
  OrderItemCreditQueryParams,
  OrderItemCreditCreateParams,
  OrderItemCreditUpdateParams
>("order-item-credits", (params) => {
  const search: TsdSearchParam<OrderItemCreditQueryParams>[] = [];

  if (typeof params.orderItemIds !== "undefined") {
    params.orderItemIds.forEach((id) => {
      search.push({ key: "orderItemIds", value: String(id) });
    });
  }

  if (typeof params.isApplied !== "undefined") {
    search.push({ key: "isApplied", value: params.isApplied ? "1" : "0" });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.hasCreditGroupId !== "undefined") {
    search.push({
      key: "hasCreditGroupId",
      value: params.hasCreditGroupId ? "1" : "0",
    });
  }

  if (typeof params.isFutureApplicationImpossible !== "undefined") {
    search.push({
      key: "isFutureApplicationImpossible",
      value: params.isFutureApplicationImpossible ? "1" : "0",
    });
  }

  return search;
});

export default orderItemCreditApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerAnalysis,
  CustomerAnalysisCreateParams,
  CustomerAnalysisQueryParams,
  CustomerAnalysisUpdateParams,
} from "../../../entities/CustomerAnalysis/customerAnalysis";
import { TsdSearchParam } from "../fetch";

const customerAnalysisApi = createTsdApi<
  CustomerAnalysis,
  CustomerAnalysisQueryParams,
  CustomerAnalysisCreateParams,
  CustomerAnalysisUpdateParams
>("customer-analysis", (params) => {
  const search: TsdSearchParam<CustomerAnalysisQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.customerIds !== "undefined") {
    params.customerIds.forEach((id) => {
      search.push({ key: "customerIds", value: String(id) });
    });
  }

  if (typeof params.customerGroupIds !== "undefined") {
    params.customerGroupIds.forEach((id) => {
      search.push({ key: "customerGroupIds", value: String(id) });
    });
  }

  if (typeof params.year !== "undefined") {
    search.push({ key: "year", value: String(params.year) });
  }

  if (typeof params.month !== "undefined") {
    search.push({ key: "month", value: String(params.month) });
  }

  if (typeof params.budgetOrCategoryCategoryId !== "undefined") {
    search.push({
      key: "budgetOrCategoryCategoryId",
      value: String(params.budgetOrCategoryCategoryId),
    });
  }

  if (typeof params.budgetOrCategoryBudgetId !== "undefined") {
    search.push({
      key: "budgetOrCategoryBudgetId",
      value: String(params.budgetOrCategoryBudgetId),
    });
  }

  return search;
});

export default customerAnalysisApi;

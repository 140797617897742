import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNote,
  CustomerNoteCreateParams,
  CustomerNoteQueryParams,
  CustomerNoteUpdateParams,
} from "../../../entities/CustomerNote/customerNote";
import { TsdSearchParam } from "../fetch";

const customerNoteApi = createTsdApi<
  CustomerNote,
  CustomerNoteQueryParams,
  CustomerNoteCreateParams,
  CustomerNoteUpdateParams
>("customer-notes", (params) => {
  const searchParams: TsdSearchParam<CustomerNoteQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.note !== "undefined") {
    searchParams.push({
      key: "note",
      value: String(params.note),
    });
  }

  if (typeof params.createdByUserIds !== "undefined") {
    params.createdByUserIds.forEach((id) => {
      searchParams.push({
        key: "createdByUserIds",
        value: String(id),
      });
    });
  }

  if (typeof params.orgTypes !== "undefined") {
    params.orgTypes.forEach((type) => {
      searchParams.push({
        key: "orgTypes",
        value: String(type),
      });
    });
  }

  if (typeof params.occurredAtGte !== "undefined") {
    searchParams.push({
      key: "occurredAtGte",
      value: params.occurredAtGte,
    });
  }

  if (typeof params.occurredAtLte !== "undefined") {
    searchParams.push({
      key: "occurredAtLte",
      value: params.occurredAtLte,
    });
  }

  if (typeof params.tags !== "undefined") {
    params.tags.forEach((tag) => {
      searchParams.push({
        key: "tags",
        value: tag,
      });
    });
  }

  if (typeof params.orgIds !== "undefined") {
    params.orgIds.forEach((id) => {
      searchParams.push({
        key: "orgIds",
        value: String(id),
      });
    });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  return searchParams;
});

export default customerNoteApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  VatRate,
  VatRateQueryParams,
  VatRateCreateParams,
  VatRateUpdateParams,
} from "../../../entities/VatRate/vatRate";
import { TsdSearchParam } from "../fetch";

const vatRateApi = createTsdApi<
  VatRate,
  VatRateQueryParams,
  VatRateCreateParams,
  VatRateUpdateParams
>("vat-rates", (searchParamsObject) => {
  let searchParams: TsdSearchParam<VatRateQueryParams>[] = [];

  if (typeof searchParamsObject.name !== "undefined") {
    searchParams.push({
      key: "name",
      value: searchParamsObject.name,
    });
  }

  if (typeof searchParamsObject.code !== "undefined") {
    searchParams.push({
      key: "code",
      value: searchParamsObject.code,
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default vatRateApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNoteRelationship,
  CustomerNoteRelationshipCreateParams,
  CustomerNoteRelationshipQueryParams,
  CustomerNoteRelationshipUpdateParams,
} from "../../../entities/CustomerNoteRelationship/customerNoteRelationship";
import { TsdSearchParam } from "../fetch";

const customerNoteRelationshipApi = createTsdApi<
  CustomerNoteRelationship,
  CustomerNoteRelationshipQueryParams,
  CustomerNoteRelationshipCreateParams,
  CustomerNoteRelationshipUpdateParams
>("customer-note-relationships", (params) => {
  const searchParams: TsdSearchParam<CustomerNoteRelationshipQueryParams>[] =
    [];

  if (typeof params.customerNoteIds !== "undefined") {
    params.customerNoteIds.forEach((customerNoteId) => {
      searchParams.push({
        key: "customerNoteIds",
        value: String(customerNoteId),
      });
    });
  }

  if (typeof params.type !== "undefined") {
    searchParams.push({
      key: "type",
      value: params.type,
    });
  }

  if (typeof params.foreignIds !== "undefined") {
    params.foreignIds.forEach((id) => {
      searchParams.push({
        key: "foreignIds",
        value: String(id),
      });
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  return searchParams;
});

export default customerNoteRelationshipApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  CardPaymentFee,
  CardPaymentFeeCreateParams,
  CardPaymentFeeQueryParams,
  CardPaymentFeeUpdateParams,
} from "../../../entities/CardPaymentFee/cardPaymentFee";
import { TsdSearchParam } from "../fetch";

const cardPaymentFeeApi = createTsdApi<
  CardPaymentFee,
  CardPaymentFeeQueryParams,
  CardPaymentFeeCreateParams,
  CardPaymentFeeUpdateParams
>("card-payment-fees", (params) => {
  const search: TsdSearchParam<CardPaymentFeeQueryParams>[] = [];

  if (typeof params.isCurrent !== "undefined") {
    search.push({ key: "isCurrent", value: params.isCurrent ? "1" : "0" });
  }

  return search;
});

export default cardPaymentFeeApi;

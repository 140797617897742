import createTsdApi from "../../../client-api/createTsdApi";
import {
  StaffTrackingTag,
  StaffTrackingTagCreateParams,
  StaffTrackingTagQueryParams,
  StaffTrackingTagUpdateParams,
} from "../../../entities/StaffTrackingTag/staffTrackingTag";
import { TsdSearchParam } from "../fetch";

const staffTrackingTagApi = createTsdApi<
  StaffTrackingTag,
  StaffTrackingTagQueryParams,
  StaffTrackingTagCreateParams,
  StaffTrackingTagUpdateParams
>("staff-tracking-tags", (params) => {
  const search: TsdSearchParam<StaffTrackingTagQueryParams>[] = [];

  if (typeof params.staffIds !== "undefined") {
    params.staffIds.forEach((id) => {
      search.push({ key: "staffIds", value: String(id) });
    });
  }

  if (typeof params.tagNames !== "undefined") {
    params.tagNames.forEach((name) => {
      search.push({ key: "tagNames", value: name });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  return search;
});

export default staffTrackingTagApi;

import {
  Budget,
  BudgetCreateParams,
  BudgetQueryParams,
} from "../../../entities/budget";
import createTsdApi from "../../../client-api/createTsdApi";
import { BudgetProductUpdateParams } from "../../../entities/BudgetProduct/budgetProduct";
import { TsdSearchParam } from "../fetch";
import dayjs from "dayjs";

const budgetApi = createTsdApi<
  Budget,
  BudgetQueryParams,
  BudgetCreateParams,
  BudgetProductUpdateParams
>("budgets", (params) => {
  const searchParams: TsdSearchParam<BudgetQueryParams>[] = [];

  if (typeof params.categoryIds !== "undefined") {
    params.categoryIds.forEach((id) => {
      searchParams.push({ key: "categoryIds", value: String(id) });
    });
  }

  if (typeof params.forProductCustomerId !== "undefined") {
    searchParams.push({
      key: "forProductCustomerId",
      value: String(params.forProductCustomerId),
    });
  }

  if (typeof params.forProductOriginalIds !== "undefined") {
    params.forProductOriginalIds.forEach((id) => {
      searchParams.push({
        key: "forProductOriginalIds",
        value: String(id),
      });
    });
  }

  if (typeof params.forProductDate !== "undefined") {
    searchParams.push({
      key: "forProductDate",
      value: dayjs()
        .fromTsdDateFormat(params.forProductDate)
        .format("YYYY-MM-DD"),
    });
  }

  if (typeof params.forOrderItemCustomerId !== "undefined") {
    searchParams.push({
      key: "forOrderItemCustomerId",
      value: String(params.forOrderItemCustomerId),
    });
  }

  if (typeof params.forOrderItemIds !== "undefined") {
    params.forOrderItemIds.forEach((id) => {
      searchParams.push({
        key: "forOrderItemIds",
        value: String(id),
      });
    });
  }

  if (typeof params.forOrderItemDate !== "undefined") {
    searchParams.push({
      key: "forOrderItemDate",
      value: dayjs()
        .fromTsdDateFormat(params.forOrderItemDate)
        .format("YYYY-MM-DD"),
    });
  }

  if (typeof params.orgIds !== "undefined") {
    searchParams.push({
      key: "orgIds",
      value: String(params.orgIds),
    });
  }

  if (typeof params.status !== "undefined") {
    searchParams.push({
      key: "status",
      value: params.status,
    });
  }

  if (typeof params.supplierIds !== "undefined") {
    params.supplierIds.forEach((id) => {
      searchParams.push({ key: "supplierIds", value: String(id) });
    });
  }

  if (typeof params.budgetTypes !== "undefined") {
    params.budgetTypes.forEach((type) => {
      searchParams.push({ key: "budgetTypes", value: type });
    });
  }

  if (typeof params.isCurrent !== "undefined") {
    searchParams.push({
      key: "isCurrent",
      value: params.isCurrent ? "1" : "0",
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  if (typeof params.forAmountDate !== "undefined") {
    searchParams.push({
      key: "forAmountDate",
      value: String(params.forAmountDate),
    });
  }

  return searchParams;
});

export default budgetApi;

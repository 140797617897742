import createTsdApi from "../../../client-api/createTsdApi";
import {
  BudgetAmount,
  BudgetAmountCreateParams,
  BudgetAmountQueryParams,
  BudgetAmountUpdateParams,
} from "../../../entities/BudgetAmount/budgetAmount";
import { TsdSearchParam } from "../fetch";
import dayjs from "dayjs";

const budgetAmountApi = createTsdApi<
  BudgetAmount,
  BudgetAmountQueryParams,
  BudgetAmountCreateParams,
  BudgetAmountUpdateParams
>("budget-amounts", (params) => {
  const search: TsdSearchParam<BudgetAmountQueryParams>[] = [];

  if (typeof params.budgetIds !== "undefined") {
    params.budgetIds.forEach((id) => {
      search.push({ key: "budgetIds", value: String(id) });
    });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.status !== "undefined") {
    search.push({ key: "status", value: params.status });
  }

  if (typeof params.yearMonths !== "undefined") {
    params.yearMonths.forEach((yearMonth) => {
      search.push({
        key: "yearMonths",
        value: yearMonth,
      });
    });
  }

  if (typeof params.notMonth !== "undefined") {
    search.push({
      key: "notMonth",
      value: String(params.notMonth),
    });
  }

  if (typeof params.weekStartDates !== "undefined") {
    params.weekStartDates.forEach((weekStartDate) => {
      search.push({
        key: "weekStartDates",
        value: weekStartDate,
      });
    });
  }

  if (typeof params.weekEndDates !== "undefined") {
    params.weekEndDates.forEach((weekEndDate) => {
      search.push({
        key: "weekEndDates",
        value: weekEndDate,
      });
    });
  }

  if (typeof params.dateGte !== "undefined") {
    search.push({
      key: "dateGte",
      value: dayjs().fromTsdDateFormat(params.dateGte).toISOString(),
    });
  }

  if (typeof params.dateLte !== "undefined") {
    search.push({
      key: "dateLte",
      value: dayjs().fromTsdDateFormat(params.dateLte).toISOString(),
    });
  }

  return search;
});

export default budgetAmountApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductAttribute,
  ProductAttributeCreateParams,
  ProductAttributeQueryParams,
  ProductAttributeUpdateParams,
} from "../../../entities/ProductAttribute/productAttribute";
import { TsdSearchParam } from "../fetch";

const productAttributeApi = createTsdApi<
  ProductAttribute,
  ProductAttributeQueryParams,
  ProductAttributeCreateParams,
  ProductAttributeUpdateParams
>("product-attributes", (searchParamsObject) => {
  const searchParams: TsdSearchParam<ProductAttributeQueryParams>[] = [];

  if (typeof searchParamsObject.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: searchParamsObject.isActive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.names !== "undefined") {
    searchParamsObject.names.forEach((name) => {
      searchParams.push({
        key: "names",
        value: name,
      });
    });
  }

  return searchParams;
});

export default productAttributeApi;

import createTsdApi from "../../../client-api/createTsdApi";
import {
  ChangeLog,
  ChangeLogCreateParams,
  ChangeLogQueryParams,
  ChangeLogUpdateParams,
} from "../../../entities/changeLog";
import { TsdSearchParam } from "../fetch";

const changeLogApi = createTsdApi<
  ChangeLog,
  ChangeLogQueryParams,
  ChangeLogCreateParams,
  ChangeLogUpdateParams
>("change-logs", (params) => {
  const search: TsdSearchParam<ChangeLogQueryParams>[] = [];

  if (typeof params.createdAtGte !== "undefined") {
    search.push({ key: "createdAtGte", value: params.createdAtGte });
  }

  if (typeof params.recordId !== "undefined") {
    search.push({ key: "recordId", value: String(params.recordId) });
  }

  if (typeof params.userId !== "undefined") {
    search.push({ key: "userId", value: String(params.userId) });
  }

  if (typeof params.table !== "undefined") {
    search.push({ key: "table", value: params.table });
  }

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.orderByCreated !== "undefined") {
    search.push({
      key: "orderByCreated",
      value: params.orderByCreated,
    });
  }

  return search;
});

export default changeLogApi;

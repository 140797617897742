import createTsdApi from "../../../client-api/createTsdApi";
import {
  Reminder,
  ReminderCreateParams,
  ReminderQueryParams,
  ReminderUpdateParams,
} from "../../../entities/reminder";
import { TsdSearchParam } from "../fetch";

const reminderApi = createTsdApi<
  Reminder,
  ReminderQueryParams,
  ReminderCreateParams,
  ReminderUpdateParams
>("reminders", (searchParamsObject) => {
  let searchParams: TsdSearchParam<ReminderQueryParams>[] = [];

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.searchTerm !== "undefined") {
    searchParams.push({
      key: "searchTerm",
      value: searchParamsObject.searchTerm,
    });
  }

  if (typeof searchParamsObject.startDateLte !== "undefined") {
    searchParams.push({
      key: "startDateLte",
      value: searchParamsObject.startDateLte,
    });
  }

  if (typeof searchParamsObject.endDate !== "undefined") {
    searchParams.push({
      key: "endDate",
      value: searchParamsObject.endDate,
    });
  }

  if (typeof searchParamsObject.dayId !== "undefined") {
    searchParams.push({
      key: "dayId",
      value: String(searchParamsObject.dayId),
    });
  }

  if (typeof searchParamsObject.isActive !== "undefined") {
    searchParams.push({
      key: "isActive",
      value: searchParamsObject.isActive ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.onHold !== "undefined") {
    searchParams.push({
      key: "onHold",
      value: searchParamsObject.onHold ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.hasBeenHandledToday !== "undefined") {
    searchParams.push({
      key: "hasBeenHandledToday",
      value: searchParamsObject.hasBeenHandledToday ? "1" : "0",
    });
  }

  return searchParams;
});

export default reminderApi;
